import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import LeafletMap from "../components/leafletMap"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <section className="container">
        <div className="section">
          <h1>Welcome to Perla!</h1>
          <p className="description">
            Situated in the old town of Mahon, we are fortunate to be located on
            a quaint cobbled side street, right in the heart of the shopping
            area.
          </p>
        </div>
        <div className="gallery-grid">
          <Img
            alt="ATLANTIC MUSSELS STEAMED IN WHITE WINE, PARSLEY, GARLIC & CHILLI"
            fluid={data.mussels.childImageSharp.fluid}
            className="gallery-img1"
          />
          <Img
            alt="Goat salad"
            fluid={data.goatSalad.childImageSharp.fluid}
            className="gallery-img2"
          />
          <Img
            alt="Perla business card in cocktail glass"
            fluid={data.cocktail.childImageSharp.fluid}
            className="gallery-img3"
          />
        </div>
        <div className="about-grid">
          <h2>About us</h2>
          <div>
            <p>
              We welcome couples, families, small groups and individuals to
              enjoy the great combination of food, service and atmosphere. Our
              simple yet versatile menu aims to please, also offering a good
              selection of gluten free and dairy free dishes.
            </p>
            <p>
              The wine list is dedicated only to the Spanish regions. We also
              offer an interesting selection of cocktails and spirits which
              reflect our global experience. Music is a very important element
              when you dine with us and I am sure that you will enjoy the
              relaxed, casual vibe, which is Perla.
            </p>
          </div>
        </div>
      </section>
      <div className="parallax"></div>
      <section className="container section">
        <h2>Gallery</h2>
        <div className="bottom-gallery-container">
          <Img
            alt="Patatas"
            fluid={data.patatas.childImageSharp.fluid}
            className="bottom-img1"
          />
          <Img
            alt="Homemade chilli"
            fluid={data.chilli.childImageSharp.fluid}
            className="bottom-img2"
          />
          <Img
            alt="Soup"
            fluid={data.soup.childImageSharp.fluid}
            className="bottom-img3"
          />
          <Img
            alt="Tempura"
            fluid={data.tempura.childImageSharp.fluid}
            className="bottom-img4"
          />
          <Img
            alt="Prawns"
            fluid={data.prawns.childImageSharp.fluid}
            className="bottom-img5"
          />
          <Img
            alt="Coffee"
            fluid={data.coffee.childImageSharp.fluid}
            className="bottom-img6"
          />
        </div>
      </section>
      {typeof window !== "undefined" && <LeafletMap />}
    </Layout>
  )
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    goatSalad: file(relativePath: { eq: "goat-salad.jpg" }) {
      ...fluidImage
    }
    cocktail: file(relativePath: { eq: "cocktail.jpeg" }) {
      ...fluidImage
    }
    mussels: file(relativePath: { eq: "mussels.jpg" }) {
      ...fluidImage
    }
    patatas: file(relativePath: { eq: "patatas.jpg" }) {
      ...fluidImage
    }
    chilli: file(relativePath: { eq: "chilli.jpg" }) {
      ...fluidImage
    }
    soup: file(relativePath: { eq: "soup.jpg" }) {
      ...fluidImage
    }
    tempura: file(relativePath: { eq: "tempura.jpg" }) {
      ...fluidImage
    }
    prawns: file(relativePath: { eq: "prawns.jpg" }) {
      ...fluidImage
    }
    coffee: file(relativePath: { eq: "coffee.jpg" }) {
      ...fluidImage
    }
  }
`

export default IndexPage
